<template>
  <div class="date__ctr">
    <div class="date__sublabel-passive">From</div>
    <input readonly type="text" class="date__input-passive" :value="beforeDate" />
    <div class="date__sublabel-passive">Until</div>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <input
          v-bind="attrs"
          v-on="on"
          :value="valueDate"
          type="text"
          class="date__input"
        />
      </template>
      <v-date-picker
          v-model="date"
          type="month"
          color="secondary"
          @input="pickDate"
          :max="limitDate"
          :min="firstDate"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment"
export default {
  props : ['firstDate'],
  methods : {
    pickDate(){
      const beforeDate = this.beforeDate
      const valueDate = this.valueDate
      const epochValue = moment.utc(valueDate, "MM/YYYY" ).endOf('month').unix()*1000
      const epochBefore = moment.utc(beforeDate, "MM/YYYY").startOf('month').unix()*1000
      const payload = {
        startAt : epochBefore,
        endAt : epochValue
      }
      this.$emit("getPayloadMonth",payload)
    }
  },
  mounted() {
    this.pickDate()
  },
  data() {
    const now = new Date();
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
    const formattedDate = lastDayOfMonth.toISOString().substr(0, 10);
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const limitDate = `${year}-${month}`
    return {
      menu2: false,
      limitDate : limitDate,
      date : formattedDate,
      startMonth : 'August',
      endMonth : 'September',
    };
  },
  computed: {
    valueDate() {
      const newDate = moment(this.date).format("MM/YYYY");
      return newDate;
    },
    beforeDate() {
      const minusDate = moment(this.date).subtract(3, "M").format("MM/YYYY");
      return minusDate;
    },
  }
};
</script>

<style lang="scss" scoped>
.date {
  &__input {
    background: #fafafa;
    box-sizing: border-box;
    padding: 9px 9px 9px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
  }
  &__input:focus {
    outline: none !important;
  }
  &__input-passive {
    background: #FAFAFA;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 9px 9px 9px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
  }
  &__input-passive:focus {
    outline: none !important;
  }
  &__ctr {
    display: flex;
    align-items: center;
  }
  &__sublabel-passive {
    color: #777777 ;
    font-size: 12px;
    font-weight: 500;
    margin-right: 8px;
  }
  &__sublabel {
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    margin-right: 8px;
    margin-left: 32px;
  }
}
</style>