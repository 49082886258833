<template>
  <div>
    <HeaderContent label="Dashboard" :list="items" />

    <div class="chart__label">Perkembangan Jumlah User</div>
    <div class="chart__container">
      <ChartUser
        :payloadMonth="payloadMonth"
        :payloadYear="payloadYear"
       />
    </div>
    <div class="chart__label">Perkembangan Jumlah Seleb</div>
    <div class="chart__container">
      <ChartSeleb
          :payloadMonth="payloadMonth"
          :payloadYear="payloadYear"
      />
    </div>

    <div class="chart__label">Pengguna harian</div>
    <div class="chart__container">
      <ChartHarian
          :payloadMonth="payloadMonth"
          :payloadYear="payloadYear"
      />
    </div>

    <div class="chart__label">Pesan push</div>
    <div class="chart__container">
      <ChartPesan
          :payloadMonth="payloadMonth"
          :payloadYear="payloadYear"
      />
    </div>

    <div class="chart__label">Unggahan User</div>
    <div class="chart__container">
      <ChartFeed/>
    </div>

    <div class="chart__label">Produk</div>
    <div class="chart__container">
      <ChartProduct/>
    </div>

    <div class="chart__label">Order</div>
    <div class="chart__container">
      <ChartOrder/>
    </div>


  
  </div>
</template>

<script>
import ChartUser from "./ChartUser";
import ChartSeleb from "./ChartSeleb";
import ChartHarian from "./ChartHarian";
import ChartPesan from "./ChartPesan";
import ChartFeed from "./ChartFeed";
import ChartProduct from "./ChartProduct"
import HeaderContent from "@/containers/HeaderContent";
import ChartOrder from "./ChartOrder"
import moment from "moment"
export default {
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: true,
        },
      ],
      payloadMonth : {},
      payloadYear : {}
    };
  },
  mounted() {
    this.getListCurrentDate()
  },
  methods : {
    getListCurrentDate() {
      const currentDate = new Date()
      const currentMonth = currentDate.getMonth()+1
      const currentYear = currentDate.getFullYear()
      let startAt
      let endAt
      if(currentMonth < 3){
        startAt = (currentYear-1)+"-"+(12+currentMonth-3).toString().padStart(2, '0');
      }else{
        startAt = currentYear+"-"+(currentMonth - 3).toString().padStart(2, '0');
      }
      endAt = currentYear+"-"+currentMonth.toString().padStart(2, '0')
      startAt = moment.utc(startAt,"YYYY-MM").startOf('month').unix()*1000
      endAt = moment.utc(endAt,"YYYY-MM").endOf('month').unix()*1000
      const monthsParam = {
        startAt : startAt,
        endAt : endAt
      }
      const yearParam = {
        startAt : currentYear -2,
        endAt : currentYear
      }
      this.payloadMonth = monthsParam
      this.payloadYear = yearParam
    }
  },
  components: {
    HeaderContent,
    ChartUser,
    ChartSeleb,
    ChartHarian,
    ChartPesan,
    ChartFeed,
    ChartProduct,
    ChartOrder
  },
};
</script>

<style lang="scss" scoped>

.chart {
  &__label {
    font-size: 14px;
    letter-spacing: 0.01em;
    font-weight: 500;
    color: #4a4a4a;
    margin-bottom: 14px;
  }
  &__container {
    background: #ffffff;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 24px;
  }
}
</style>